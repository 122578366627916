import { Routes } from '@angular/router';

export const ROUTES_PROXIMITY_SEARCH: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/search-summary/search-summary.component').then(
        (m) => m.SearchSummaryComponent
      ),
  },
  {
    path: 'list',
    loadComponent: () =>
      import(
        './components/proximity-search-list-view/proximity-search-list-view.component'
      ).then((m) => m.ProximitySearchListViewComponent),
  },
  {
    path: 'condition-builder',
    loadComponent: () =>
      import('./components/conditions-search/conditions-search.component').then(
        (m) => m.ConditionsSearchComponent
      ),
  },
  {
    path: 'proximity-live',
    loadComponent: () =>
      import(
        './components/proximity-search-snapshot/proximity-search-snapshot.component'
      ).then((m) => m.ProximitySearchSnapshotComponent),
  },
  {
    path: 'proximity-historical',
    loadComponent: () =>
      import(
        './components/proximity-search-snapshot/proximity-search-snapshot.component'
      ).then((m) => m.ProximitySearchSnapshotComponent),
  },
  {
    path: '',
    redirectTo: 'search/cards',
    pathMatch: 'full',
  },
];
