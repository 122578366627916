import { inject } from '@angular/core';
import { Routes, ActivatedRouteSnapshot } from '@angular/router';
import { RouteAuthGuard } from '@app/shared/services/route-guard.service';
import { ObjectIdResolver } from '@app/shared/resolver/object-id.resolver';

export const ROUTES_GROUP: Routes = [
  { path: '', redirectTo: 'cards', pathMatch: 'prefix' },
  {
    path: 'cards',
    loadComponent: () =>
      import('./pages/groups/groups.component').then((m) => m.GroupsComponent),
  },
  {
    path: 'list',
    loadComponent: () =>
      import('./pages/group-list/group-list.component').then(
        (m) => m.GroupListComponent
      ),
  },
  {
    path: 'add',
    loadComponent: () =>
      import('./pages/add-group/add-group.component').then(
        (m) => m.AddGroupComponent
      ),
  },
  {
    path: ':objectId',
    loadComponent: () =>
      import('./pages/group-details/group-details.component').then(
        (m) => m.GroupDetailsComponent
      ),
    children: [
      { path: '', redirectTo: 'snapshot', pathMatch: 'prefix' },
      {
        path: 'snapshot',
        loadComponent: () =>
          import(
            './containers/group-snapshot-container/group-snapshot-container.component'
          ).then((m) => m.GroupSnapshotContainerComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'groups', subModule: 'info' },
      },
      {
        path: 'members',
        loadComponent: () =>
          import(
            './containers/group-members-container/group-members-container.component'
          ).then((m) => m.GroupMembersContainerComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'groups', subModule: 'members' },
      },
      {
        path: 'dynamic-members',
        loadComponent: () =>
          import('./components/dynamic-members/dynamic-members.component').then(
            (m) => m.DynamicMembersComponent
          ),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'groups', subModule: 'dynamic-members' },
      },
      {
        path: 'geofence-members',
        loadComponent: () =>
          import(
            './components/asset-group-geofence/asset-group-geofence.component'
          ).then((m) => m.AssetGroupGeofencesComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'groups', subModule: 'geofence-members' },
      },
      {
        path: 'security-geofence-members',
        loadComponent: () =>
          import(
            './components/security-group-geofence/security-group-geofence.component'
          ).then((m) => m.SecurityGroupGeofenceComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'groups', subModule: 'geofence-members' },
      },
      {
        path: 'security-dashboard-members',
        loadComponent: () =>
          import(
            './components/security-group-dashboard/security-group-dashboard.component'
          ).then((m) => m.SecurityGroupDashboardComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'groups', subModule: 'geofence-members' },
      },
      {
        path: 'configuration',
        loadComponent: () =>
          import(
            './containers/group-configuration-container/group-configuration-container.component'
          ).then((m) => m.GroupConfigurationContainerComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'groups', subModule: 'configuration' },
      },
      {
        path: 'audit-log',
        loadComponent: () =>
          import('../../shared/components/audit-log/audit-log.component').then(
            (m) => m.AuditLogComponent
          ),
        resolve: { objectId: ObjectIdResolver },
        data: { module: 'groups', subModule: 'audit-log' },
      },
    ],
  },
];
