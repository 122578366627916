import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import { SELECT_SITE } from '../selectors/selectors';
import {
  loadSitesData,
  loadSitesFailure,
  loadSitesSuccess,
} from '../actions/sites.action';

@Injectable()
export class SitesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly appHttpService: AppHttpService,
    private readonly store: Store<AppState>
  ) {}

  loadSiteData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSitesData),
      withLatestFrom(this.store.select(SELECT_SITE)),
      filter(([_action, ruleData]) => !ruleData?.data?.length),
      switchMap(([_action, _ruleData]) => {
        return this.appHttpService.getAllSites().pipe(
          map((data) => {
            if (data) {
              // MOCK LOCATION DUE TO INCOMPLETE DATA FROM BE. TO BE REMOVED____________________________________________________________
              data.forEach((d) => {
                d.locationHierarchyL1 = d.locationHierarchyL1
                  ? d.locationHierarchyL1
                  : 'Site';
                d.locationHierarchyL2 = d.locationHierarchyL2
                  ? d.locationHierarchyL2
                  : 'Location';
                d.locationHierarchyL3 = d.locationHierarchyL3
                  ? d.locationHierarchyL3
                  : '';
              });

              return loadSitesSuccess({ payload: data });
            }
          }),
          catchError((error) => of(loadSitesFailure({ error })))
        );
      })
    )
  );
}
