import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { LocalisationService } from '@app/shared/services/localisation.service';
import { format } from 'date-fns';
import { take } from 'rxjs';
import { Button } from 'primeng/button';

@Component({
  selector: 'app-session-idle-dialog',
  templateUrl: './session-idle-dialog.component.html',
  styleUrls: ['./session-idle-dialog.component.scss'],
  standalone: true,
  imports: [Button],
})
export class SessionIdleDialogComponent implements OnInit, OnDestroy {
  subs = [];
  constructor(
    private localisationService: LocalisationService,
    private appHttpService: AppHttpService
  ) {}

  expiredDate = format(
    new Date(),
    this.localisationService?.dates?.DATE_LABEL_FORMAT ??
      'iii dd-MMM-yy HH:mm:ssaaa'
  );

  ngOnInit(): void {
    this.logEvent();
  }

  logEvent(): void {
    const user = JSON.parse(localStorage?.getItem('currentUser'));
    this.subs.push(
      this.appHttpService
        .writeAuditLog({
          TenantId: user?.tid,
          UserId: user?.id,
          Email: user?.email,
          Description: 'Session Expired',
          Timestamp: new Date(),
          AuditLogType: 'SessionExpired',
          userName: user?.userName,
        })
        .pipe(take(1))
        .subscribe()
    );
  }

  reload(): void {
    location.reload();
  }

  contactSupport(): void {
    window.open('mailto:support@inauro.io', '_blank');
  }

  getRoute(): string {
    return location.href;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
