import { inject, Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  PreloadingStrategy,
  Route,
  Routes,
  provideRouter,
  withDisabledInitialNavigation,
  withPreloading,
} from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { MsalGuard } from '@azure/msal-angular';
import { Observable, of, takeWhile } from 'rxjs';
import { Store } from '@ngrx/store';

import { DataResolver } from './shared/resolver';
import { UserDataResolver } from './shared/resolver/user-data.resolver';
import { RouteAuthGuard } from '@app/shared/services/route-guard.service';
import { DevelopmentGuard } from './shared/guards/development.guard';
import { LoginGuard } from './shared/guards/login.guard';
import { DataLoadGuard } from './shared/guards/data-load.guard';
import { AppState } from './shared/store/appState';
import {
  SYSTEM_OBJECTS_LOAD_LIST_ADMIN,
  SYSTEM_OBJECTS_LOAD_LIST_ASSETS,
  SYSTEM_OBJECTS_LOAD_LIST_CONNECTORS,
  SYSTEM_OBJECTS_LOAD_LIST_DASHBOARDS,
  SYSTEM_OBJECTS_LOAD_LIST_EXPLORER,
  SYSTEM_OBJECTS_LOAD_LIST_GEOFENCES,
  SYSTEM_OBJECTS_LOAD_LIST_GROUPS,
  SYSTEM_OBJECTS_LOAD_LIST_HOME,
  SYSTEM_OBJECTS_LOAD_LIST_INVENTORY,
  SYSTEM_OBJECTS_LOAD_LIST_REPORTS,
  SYSTEM_OBJECTS_LOAD_LIST_SERVICING,
  SYSTEM_OBJECTS_LOAD_LIST_TRANSPORT,
  SYSTEM_OBJECTS_LOAD_LIST_UTILISATION,
  SYSTEM_OBJECTS_LOAD_LIST_WORKFLOWS,
  SYSTEM_OBJECTS_LOAD_PEOPLE,
} from './shared/store/route-data-load-list';
import { ROUTES_PROXIMITY_SEARCH } from './perspio/search/search.routing';

import { ROUTES_ADMIN } from './perspio/admin/admin-routing.config';
import { ROUTES_ASSET_TRACKER } from './perspio/inspector/asset-tracker.routing';
import { ROUTES_CONNECTORS } from './perspio/connectors/connectors-routing.config';
import { ROUTES_DASHBOARD } from './perspio/dashboards/dashboards-routing.config';
import { ROUTES_GEOFENCE } from './perspio/geofences/geofences-routing.config';
import { ROUTES_GROUP } from './perspio/groups/groups.routing';
import { ROUTES_INVENTORY } from './perspio/inventory/inventory-routing.config';
import { ROUTES_REPORTING } from './perspio/reporting/reporting-routing.config';
import { ROUTES_SERVICING } from './perspio/servicing/servicing-routing.config';
import { ROUTES_TRANSPORT } from './perspio/transport/transport.routing';
import { ROUTES_UTILISATION } from './perspio/utilisation/utilisation.routing';
import { ROUTES_WORKFLOW } from './perspio/workflow/workflow-routing.config';

@Injectable({ providedIn: 'root' })
export class SelectivePreloadingStrategy
  implements PreloadingStrategy, OnDestroy
{
  private readonly preloadedModules: string[] = [];
  private subscriptions: { [k: string]: any } = {};
  userDataSelector = (state: AppState) => state.UserData;

  constructor(private readonly stateStore: Store<AppState>) {}

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    let hasData = false;
    if (route.data?.['preloadIfLoggedIn']) {
      this.subscriptions[route.path] = this.stateStore
        .select(this.userDataSelector)
        .pipe(takeWhile(() => !hasData))
        .subscribe((data) => {
          if (data) {
            if (this.subscriptions[route.path]) {
              this.subscriptions[route.path].unsubscribe();
            }
            if (!this.preloadedModules.includes(route.path)) {
              this.preloadedModules.push(route.path);
              return load();
            }
          }
          hasData = true;
        });
    }
    return of(null);
  }

  ngOnDestroy(): void {
    Object.keys(this.subscriptions)?.forEach((k) =>
      this.subscriptions?.[k]?.unsubscribe()
    );
  }
}

// perspio app routes
const routes: Routes = [
  {
    path: 'not-auth',
    loadComponent: () =>
      import(
        './shared/components/not-authorised/not-authorised.component'
      ).then((m) => m.NotAuthorisedComponent),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./perspio/user/pages/login/login.component').then(
        (m) => m.LoginComponent
      ),
    canActivate: [() => inject(LoginGuard).canActivate()],
  },
  {
    path: 'tenant-selection',
    loadComponent: () =>
      import(
        './perspio/user/pages/login-tenant-selection/login-tenant-selection.component'
      ).then((m) => m.LoginTenantSelectionComponent),
    canActivate: [MsalGuard],
  },
  {
    path: '',
    loadComponent: () =>
      import('./perspio/app-wrapper/app-wrapper/app-wrapper.component').then(
        (m) => m.AppWrapperComponent
      ),
    resolve: {
      data: () => inject(DataResolver).resolve(),
      userData: UserDataResolver,
    },
    canActivate: [MsalGuard],
    children: [
      {
        path: 'home',
        loadComponent: () =>
          import('./perspio/home/pages/home/home.component').then(
            (m) => m.HomeComponent
          ),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () =>
            inject(DataLoadGuard).canActivate(SYSTEM_OBJECTS_LOAD_LIST_HOME),
        ],
        data: { module: 'home', subModule: null },
      },
      {
        path: 'assets',
        loadChildren: () => ROUTES_ASSET_TRACKER,
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () =>
            inject(DataLoadGuard).canActivate(SYSTEM_OBJECTS_LOAD_LIST_ASSETS),
        ],
        data: {
          module: 'assets',
          subModule: null,
          preloadIfLoggedIn: true,
        },
      },
      {
        path: 'people',
        loadComponent: () =>
          import('./perspio/inventory/components/people/people.component').then(
            (m) => m.PeopleComponent
          ),

        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () => inject(DataLoadGuard).canActivate(SYSTEM_OBJECTS_LOAD_PEOPLE),
        ],
        data: { module: 'people', subModule: null },
      },
      {
        path: 'search',
        loadChildren: () => ROUTES_PROXIMITY_SEARCH,
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () => inject(DataLoadGuard).canActivate(SYSTEM_OBJECTS_LOAD_PEOPLE),
        ],
        data: { module: 'search', subModule: null },
      },
      {
        path: 'reports',
        loadChildren: () => ROUTES_REPORTING,
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () =>
            inject(DataLoadGuard).canActivate(SYSTEM_OBJECTS_LOAD_LIST_REPORTS),
        ],
        data: { module: 'reports', subModule: null },
      },
      {
        path: 'inventory',
        loadComponent: () =>
          import(
            './perspio/inventory/pages/inventory/inventory.component'
          ).then((m) => m.InventoryComponent),
        children: ROUTES_INVENTORY,
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () =>
            inject(DataLoadGuard).canActivate(
              SYSTEM_OBJECTS_LOAD_LIST_INVENTORY
            ),
        ],
        data: { module: 'inventory', subModule: null },
      },
      {
        path: 'geofences',
        loadChildren: () => ROUTES_GEOFENCE,
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () =>
            inject(DataLoadGuard).canActivate(
              SYSTEM_OBJECTS_LOAD_LIST_GEOFENCES
            ),
        ],
        data: { module: 'geofences', subModule: null },
      },
      {
        path: 'workflows',
        loadChildren: () => ROUTES_WORKFLOW,
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () =>
            inject(DataLoadGuard).canActivate(
              SYSTEM_OBJECTS_LOAD_LIST_WORKFLOWS
            ),
        ],
        data: { module: 'workflows', subModule: null },
      },
      {
        path: 'connectors',

        loadChildren: () => ROUTES_CONNECTORS,
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () =>
            inject(DataLoadGuard).canActivate(
              SYSTEM_OBJECTS_LOAD_LIST_CONNECTORS
            ),
        ],
        data: { module: 'connectors', subModule: null },
      },
      {
        path: 'user-profile',
        loadComponent: () =>
          import(
            './perspio/user-profile/pages/user-profile/user-profile.component'
          ).then((m) => m.UserProfileComponent),
      },
      {
        path: 'admin',
        loadChildren: () => ROUTES_ADMIN,
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () =>
            inject(DataLoadGuard).canActivate(SYSTEM_OBJECTS_LOAD_LIST_ADMIN),
        ],
        data: { module: 'admin', subModule: null },
      },
      {
        path: 'dashboards',
        loadChildren: () => ROUTES_DASHBOARD,
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () =>
            inject(DataLoadGuard).canActivate(
              SYSTEM_OBJECTS_LOAD_LIST_DASHBOARDS
            ),
        ],
        data: { module: 'dashboards', subModule: null },
      },
      {
        path: 'work-orders',
        loadComponent: () =>
          import(
            './perspio/work-orders/components/work-orders/work-orders.component'
          ).then((m) => m.WorkOrdersComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'work-orders', subModule: null },
      },
      {
        path: 'servicing',
        loadChildren: () => ROUTES_SERVICING,
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () =>
            inject(DataLoadGuard).canActivate(
              SYSTEM_OBJECTS_LOAD_LIST_SERVICING
            ),
        ],
        data: { module: 'servicing', subModule: null },
      },
      {
        path: 'utilisation',
        loadChildren: () => ROUTES_UTILISATION,
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () =>
            inject(DataLoadGuard).canActivate(
              SYSTEM_OBJECTS_LOAD_LIST_UTILISATION
            ),
        ],
        data: { module: 'utilisation', subModule: null },
      },
      {
        path: 'transport',
        loadChildren: () => ROUTES_TRANSPORT,
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () =>
            inject(DataLoadGuard).canActivate(
              SYSTEM_OBJECTS_LOAD_LIST_TRANSPORT
            ),
        ],
        data: { module: 'transport', subModule: null },
      },
      {
        path: 'data-explorer',
        loadComponent: () =>
          import('./perspio/data-explorer/pages/data-explorer.component').then(
            (m) => m.DataExplorerComponent
          ),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () =>
            inject(DataLoadGuard).canActivate(
              SYSTEM_OBJECTS_LOAD_LIST_EXPLORER
            ),
        ],
        data: { module: 'data-explorer', subModule: null },
      },
      {
        path: 'groups',
        loadChildren: () => ROUTES_GROUP,
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
          () =>
            inject(DataLoadGuard).canActivate(SYSTEM_OBJECTS_LOAD_LIST_GROUPS),
        ],
        data: { module: 'groups', subModule: null },
      },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
    ],
  },
  {
    path: 'info',
    children: [
      { path: '', redirectTo: 'privacy', pathMatch: 'full' },
      {
        path: 'terms',
        loadComponent: () =>
          import('./perspio/user/pages/login-info/login-info.component').then(
            (m) => m.LoginInfoComponent
          ),
      },
      {
        path: 'security',
        loadComponent: () =>
          import('./perspio/user/pages/login-info/login-info.component').then(
            (m) => m.LoginInfoComponent
          ),
      },
      {
        path: 'privacy',
        loadComponent: () =>
          import('./perspio/user/pages/login-info/login-info.component').then(
            (m) => m.LoginInfoComponent
          ),
      },
      {
        path: 'contact',
        loadComponent: () =>
          import('./perspio/user/pages/login-info/login-info.component').then(
            (m) => m.LoginInfoComponent
          ),
      },
    ],
  },
  {
    path: 'page-not-found',
    loadComponent: () =>
      import(
        './shared/components/page-not-found/page-not-found.component'
      ).then((m) => m.PageNotFoundComponent),
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import(
        './perspio/app-wrapper/privacy-policy/privacy-policy.component'
      ).then((m) => m.PrivacyPolicyComponent),
  },
  {
    path: 'terms-of-service',
    loadComponent: () =>
      import(
        './perspio/app-wrapper/terms-of-service/terms-of-service.component'
      ).then((m) => m.TermsOfServiceComponent),
  },
  {
    path: 'style-guide',
    canActivate: [() => inject(DevelopmentGuard).canActivate()],
    loadComponent: () =>
      import('./perspio/style-guide/style-guide.component').then(
        (m) => m.StyleGuideComponent
      ),
  },
  {
    path: '',
    loadComponent: () =>
      import(
        './share-perspio/share-app-wrapper/share-app-wrapper.component'
      ).then((m) => m.ShareAppWrapperComponent),
    children: [
      {
        path: 'asset/:token',
        loadComponent: () =>
          import(
            './share-perspio/share-asset/pages/share-asset/share-asset.component'
          ).then((m) => m.ShareAssetComponent),
      },
      {
        path: 'dashboard/:token',
        loadComponent: () =>
          import(
            './share-perspio/share-dashboard/pages/share-asset/share-dashboard.component'
          ).then((m) => m.ShareDashboardComponent),
      },
    ],
  },
  {
    path: 'default',
    loadComponent: () =>
      import('./share-perspio/default-landing/default-landing.component').then(
        (m) => m.DefaultLandingComponent
      ),
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'page-not-found', pathMatch: 'prefix' },
];

const routerFeatures = [];

routerFeatures.push(withPreloading(SelectivePreloadingStrategy));
if (BrowserUtils.isInIframe() && BrowserUtils.isInPopup()) {
  routerFeatures.push(withDisabledInitialNavigation());
}

export const ROUTER_PROVIDER = provideRouter(routes, ...routerFeatures);
