import { SELECT_ASSET_SHARING_PROFILES } from '../selectors/selectors';
import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, switchMap, of, map, catchError, filter } from 'rxjs';
import { AppState } from '../appState';
import {
  loadAssetSharingData,
  loadAssetSharingFailure,
  loadAssetSharingSuccess,
} from '../actions/asset-sharing-profiles.action';

@Injectable()
export class AssetSharingProfilesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly appHttpService: AppHttpService,
    private readonly store: Store<AppState>
  ) {}
  AssetSharingProfilessData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAssetSharingData),
      withLatestFrom(this.store.select(SELECT_ASSET_SHARING_PROFILES)),
      filter(
        ([_action, assetSharingProfilesState]) =>
          !assetSharingProfilesState?.data
      ),
      switchMap(() => {
        return this.appHttpService.getAssetSharingProfiles().pipe(
          map((assetSHaringProfiles) =>
            loadAssetSharingSuccess({ payload: assetSHaringProfiles })
          ),
          catchError((error) => of(loadAssetSharingFailure({ error })))
        );
      })
    )
  );
}
