import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import {
  loadReportData,
  loadReportsSuccess,
  loadReportsFailure,
} from '../actions/reports.action';
import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import { SELECT_REPORT } from '../selectors/selectors';

@Injectable()
export class ReportEffects {
  constructor(
    private actions$: Actions,
    private appHttpService: AppHttpService,
    private store: Store<AppState>
  ) {}

  loadReportData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadReportData),
      withLatestFrom(this.store.select(SELECT_REPORT)),
      filter(([_action, reports]) => reports === null),
      switchMap(([_action, _reports]) =>
        this.appHttpService.getReports().pipe(
          map((data) => loadReportsSuccess({ payload: data })),
          catchError((error) => of(loadReportsFailure({ error })))
        )
      )
    )
  );
}
