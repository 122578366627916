import { SELECT_WORKING_HOURS } from './../selectors/selectors';
import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, switchMap, of, map, catchError, filter } from 'rxjs';
import { AppState } from '../appState';
import {
  loadWorkingHoursData,
  loadWorkingHoursFailure,
  loadWorkingHoursSuccess,
} from '../actions/working-hours.action';

@Injectable()
export class WorkingHoursEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly appHttpService: AppHttpService,
    private readonly store: Store<AppState>
  ) {}
  WOrkingHoursData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWorkingHoursData),
      withLatestFrom(this.store.select(SELECT_WORKING_HOURS)),
      filter(([_action, workingHoursState]) => !workingHoursState?.data),
      switchMap(() => {
        return this.appHttpService.getWorkingHoursProfiles().pipe(
          map((workingHours) =>
            loadWorkingHoursSuccess({ payload: workingHours })
          ),
          catchError((error) => of(loadWorkingHoursFailure({ error })))
        );
      })
    )
  );
}
