import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import {
  loadGeofenceData,
  loadGeofencesSuccess,
} from '../actions/geofences.action';
import { SELECT_GEOFENCE } from '../selectors/selectors';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GeofenceEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly store: Store<AppState>
  ) {}

  loadGeofenceData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGeofenceData),
      withLatestFrom(this.store.select(SELECT_GEOFENCE)),
      filter(
        ([_action, geofenceData]) =>
          geofenceData?.data?.hasOwnProperty('kmls') === false
      ),
      switchMap(([_action, _geofenceData]) =>
        combineLatest([
          this.http.get<any>(environment.baseUri + '/geofences/v3/tollgates'),
          this.http.get<any>(environment.baseUri + '/geofences/v3'),
        ]).pipe(
          map(([tolls, geofences]) => {
            return loadGeofencesSuccess({
              payload: {
                tolls,
                geofences,
              },
            });
          })
        )
      )
    )
  );
}
