import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { withLatestFrom, switchMap, of, map, catchError, filter } from 'rxjs';
import {
  loadPlatformHealthData,
  loadPlatformHealthDataSuccess,
  loadPlatformHealthFailure,
} from '../actions/platform-health-actions.action';
import { AppState } from '../appState';
import { SELECT_PLATFORM_HEALTH } from '../selectors/selectors';

@Injectable()
export class PlatformHealthDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly appHttpService: AppHttpService,
    private readonly store: Store<AppState>
  ) {}

  loadPlatformHealthData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPlatformHealthData),
      withLatestFrom(this.store.select(SELECT_PLATFORM_HEALTH)),
      filter(([_action, platformHealthData]) => {
        return platformHealthData.actions === null;
      }),
      switchMap(([_action, _platformHealthData]) => {
        return this.appHttpService.getActions().pipe(
          map((actions) =>
            actions.map((val) => ({
              title: val.pipelineName,
              name: val.pipelineName,
              id: val.id,
              type: val.type,
              enabled: val.isGlobal ? val.isGlobal : val.enabled,
              configuration: val.requiredConfiguration,
              configurations: val?.configurations,
              inputs: val.inputs,
              category: val.category,
              usage: val.usage || 0,
              quota: val?.quota,
              configured: val?.isGlobal || val?.configured,
              description: val?.pipelineDescription,
              allowedWorkflowTriggerTypes: val?.allowedWorkflowTriggerTypes,
              percentage:
                val.usage !== null && val?.quota !== null
                  ? ((Number(val.usage) / Number(val.quota)) * 100).toFixed()
                  : 0,
            }))
          ),
          catchError((error) => of(loadPlatformHealthFailure({ error })))
        );
      }),
      map((actions) => loadPlatformHealthDataSuccess({ payload: actions }))
    )
  );
}
