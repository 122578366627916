import { Injectable } from '@angular/core';
import { AppHttpService } from '@app/shared/services/app-http.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';

import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import { SELECT_DEVICE } from '../selectors/selectors';
import {
  loadDeviceData,
  loadDeviceFailure,
  loadDeviceSuccess,
} from '../actions/devices.action';

@Injectable()
export class DeviceEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly appHttpService: AppHttpService,
    private readonly store: Store<AppState>
  ) {}

  loadDevicesData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDeviceData),
      withLatestFrom(this.store.select(SELECT_DEVICE)),
      filter(([_action, devices]) => devices?.deviceList === null),
      switchMap(([_action, _dashboards]) =>
        this.appHttpService.getDevices().pipe(
          map((data) => loadDeviceSuccess({ payload: data })),
          catchError((error) => of(loadDeviceFailure({ error })))
        )
      )
    )
  );
}
