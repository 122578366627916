import { createAction, props } from '@ngrx/store';
import {
  GET_ASSETS_MASTER_DATA_ERROR,
  GET_ASSETS_MASTER_DATA_LOADING,
  STORE_ASSETS_MASTER_DATA,
} from './action-types';

export const loadAssetsMasterData = createAction(
  GET_ASSETS_MASTER_DATA_LOADING
);

export const loadAssetsMasterDataSuccess = createAction(
  STORE_ASSETS_MASTER_DATA,
  props<{ payload: any[] }>()
);

export const loadAssetsMasterDataFailure = createAction(
  GET_ASSETS_MASTER_DATA_ERROR,
  props<{ error: any }>()
);
