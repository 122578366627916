import { Routes } from '@angular/router';

export const ROUTES_UTILISATION: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./components/utilisation/utilisation.component').then(
        (m) => m.UtilisationComponent
      ),
    children: [
      { path: '', redirectTo: 'meter-readings', pathMatch: 'prefix' },
      {
        path: 'meter-readings',
        loadComponent: () =>
          import('./components/meter-readings/meter-readings.component').then(
            (m) => m.MeterReadingsComponent
          ),
      },
      {
        path: 'daily-utilisation',
        loadComponent: () =>
          import(
            './components/averaged-utilisation/averaged-utilisation.component'
          ).then((m) => m.AveragedUtilisationComponent),
      },
      {
        path: 'fuel-consumption',
        loadComponent: () =>
          import(
            './components/fuel-consumption/fuel-consumption.component'
          ).then((m) => m.FuelConsumptionComponent),
      },
      {
        path: 'utilisation-explorer',
        loadComponent: () =>
          import(
            './components/utilisation-explorer/utilisation-explorer.component'
          ).then((m) => m.UtilisationExplorerComponent),
      },
    ],
  },
];
