import { InjectionToken } from '@angular/core';
import { SYSTEM_OBJECT } from '../schemas/enums/system-object.enum';

export const SYSTEM_OBJECTS_TOKEN = new InjectionToken<string>('');

// assets modules

export const SYSTEM_OBJECTS_LOAD_LIST_ASSETS = [
  SYSTEM_OBJECT.ASSETS,
  SYSTEM_OBJECT.CUSTOM_COUNTERS,
  SYSTEM_OBJECT.WORKING_HOURS,
  SYSTEM_OBJECT.ASSET_GROUPS,
  SYSTEM_OBJECT.GEOFENCES,
  SYSTEM_OBJECT.ASSET_SHARING_PROFILES,
  SYSTEM_OBJECT.HIERARCHIES_DATA,
  SYSTEM_OBJECT.INVENTORY,
  SYSTEM_OBJECT.SECURITY_GROUP,
];

export const SYSTEM_OBJECTS_LOAD_LIST_UTILISATION = [
  SYSTEM_OBJECT.ASSETS,
  SYSTEM_OBJECT.HIERARCHIES_DATA,
];

export const SYSTEM_OBJECTS_LOAD_LIST_SERVICING = [
  SYSTEM_OBJECT.ASSETS,
  SYSTEM_OBJECT.SERVICING_DATA,
  SYSTEM_OBJECT.HIERARCHIES_DATA,
];

// Non assets modules

export const SYSTEM_OBJECTS_LOAD_LIST_DASHBOARDS = [
  SYSTEM_OBJECT.DASHBOARDS,
  SYSTEM_OBJECT.ASSET_GROUPS,
  SYSTEM_OBJECT.ASSETS,
  SYSTEM_OBJECT.GEOFENCES,
];

export const SYSTEM_OBJECTS_LOAD_LIST_CONNECTORS = [
  SYSTEM_OBJECT.PLATFORM_HEALTH_DATA,
  SYSTEM_OBJECT.DEVICES,
];

export const SYSTEM_OBJECTS_LOAD_LIST_REPORTS = [
  SYSTEM_OBJECT.REPORTS,
  SYSTEM_OBJECT.SCHEDULED_REPORTS,
  SYSTEM_OBJECT.ASSET_GROUPS,
  SYSTEM_OBJECT.ASSETS,
];

export const SYSTEM_OBJECTS_LOAD_LIST_GROUPS = [
  SYSTEM_OBJECT.GEOFENCES,
  SYSTEM_OBJECT.ASSET_GROUPS,
  SYSTEM_OBJECT.SECURITY_GROUP,
  SYSTEM_OBJECT.DASHBOARDS,
];

export const SYSTEM_OBJECTS_LOAD_LIST_EXPLORER = [
  SYSTEM_OBJECT.ASSET_GROUPS,
  SYSTEM_OBJECT.ASSETS,
];

export const SYSTEM_OBJECTS_LOAD_LIST_SITES = [SYSTEM_OBJECT.SITES];

export const SYSTEM_OBJECTS_LOAD_PEOPLE = [SYSTEM_OBJECT.PEOPLE];

export const SYSTEM_OBJECTS_LOAD_LIST_INVENTORY = [
  SYSTEM_OBJECT.ASSETS,
  SYSTEM_OBJECT.DEVICES,
  SYSTEM_OBJECT.ASSETS_MASTER_SCHEMA,
  SYSTEM_OBJECT.ASSETS_MASTER_DATA,
];

export const SYSTEM_OBJECTS_LOAD_LIST_ADMIN = [
  SYSTEM_OBJECT.REPORTS,
  SYSTEM_OBJECT.DEVICES,
  SYSTEM_OBJECT.CUSTOM_COUNTERS,
  SYSTEM_OBJECT.SECURITY_GROUP,
  SYSTEM_OBJECT.WORKING_HOURS,
  SYSTEM_OBJECT.ASSET_SHARING_PROFILES,
  SYSTEM_OBJECT.INVENTORY,
];

export const SYSTEM_OBJECTS_LOAD_LIST_WORKFLOWS = [
  SYSTEM_OBJECT.REPORTS,
  SYSTEM_OBJECT.PLATFORM_HEALTH_DATA,
  SYSTEM_OBJECT.RULES,
  SYSTEM_OBJECT.ASSET_GROUPS,
  SYSTEM_OBJECT.INVENTORY,
];

export const SYSTEM_OBJECTS_LOAD_LIST_HOME = [
  SYSTEM_OBJECT.ASSETS,
  SYSTEM_OBJECT.INVENTORY,
  SYSTEM_OBJECT.PEOPLE,
];

export const SYSTEM_OBJECTS_LOAD_LIST_GEOFENCES = [
  SYSTEM_OBJECT.GEOFENCES,
  SYSTEM_OBJECT.ASSET_GROUPS,
];

export const SYSTEM_OBJECTS_LOAD_LIST_SELECT_ASSETS = [SYSTEM_OBJECT.ASSETS];

export const SYSTEM_OBJECTS_LOAD_LIST_TRANSPORT = [SYSTEM_OBJECT.ASSETS];
