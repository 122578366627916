import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SystemObjectInitialisationService } from '../services/system-object-initialisation.service';

@Injectable({
  providedIn: 'root',
})
export class DataLoadGuard implements CanActivate {
  constructor(private readonly authService: SystemObjectInitialisationService) {}
  canActivate(
    list: any
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.loadData(list);
  }
}
