import {
  GET_SYSTEM_EVENTS_LOADING,
  GET_SYSTEM_EVENTS_ERROR,
  GET_SYSTEM_EVENTS_SUCCESS,
  ADD_SYSTEM_EVENTS,
} from '../actions/action-types';

const initialState: SystemEventState = {
  systemEvents: [],
  systemEventsLoading: false,
  systemEventsHasError: false,
};

export function systemEventsReducer(state = initialState, action): any {
  switch (action.type) {
    case GET_SYSTEM_EVENTS_ERROR:
      return {
        systemEventsHasError: true,
        systemEventsLoading: false,
        systemEvents: null,
      };
    case GET_SYSTEM_EVENTS_SUCCESS:
      return {
        systemEventsHasError: false,
        systemEventsLoading: false,
        systemEvents: action.payload || [],
      };

    case GET_SYSTEM_EVENTS_LOADING:
      return {
        systemEventsHasError: false,
        systemEventsLoading: true,
        systemEvents: null,
      };

    case ADD_SYSTEM_EVENTS:
      return {
        systemEventsHasError: false,
        systemEventsLoading: true,
        systemEvents: [...[action.payload], ...state.systemEvents],
      };

    default: {
      return state;
    }
  }
}
