import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import {
  loadScheduledReports,
  loadScheduledReportsSuccess,
  loadScheduledReportsFailure,
} from '../actions/scheduled-reports.action';
import { Store } from '@ngrx/store';
import { AppState } from '../appState';
import { SELECT_SCHEDULED_REPORTS } from '../selectors/selectors';
import { ScheduleService } from '@app/shared/services/schedule.service';

@Injectable()
export class ScheduledReportsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly scheduleService: ScheduleService,
    private readonly store: Store<AppState>
  ) {}

  loadScheduledReportsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadScheduledReports),
      withLatestFrom(this.store.select(SELECT_SCHEDULED_REPORTS)),
      filter(([action, reports]) => reports === null || action.forceFetch),
      switchMap(([_action, _reports]) =>
        this.scheduleService.getScheduledReports().pipe(
          map((data) => loadScheduledReportsSuccess({ payload: data })),
          catchError((error) => of(loadScheduledReportsFailure({ error })))
        )
      )
    )
  );
}
