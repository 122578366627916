import { inject } from '@angular/core';
import { Routes, ActivatedRouteSnapshot } from '@angular/router';
import { RouteAuthGuard } from '@app/shared/services/route-guard.service';
import { ObjectIdResolver } from '@app/shared/resolver/object-id.resolver';

export const ROUTES_GEOFENCE: Routes = [
  { path: '', redirectTo: 'map', pathMatch: 'prefix' },
  {
    path: 'map',
    loadComponent: () =>
      import('./pages/geofence-management/geofences.component').then(
        (m) => m.GeofenceManagementComponent
      ),
  },
  {
    path: 'list',
    loadComponent: () =>
      import('./components/geofence-list/geofence-list.component').then(
        (m) => m.GeofenceListComponent
      ),
  },

  {
    path: 'add',
    loadComponent: () =>
      import('./pages/add-geofence/add-geofence.component').then(
        (m) => m.AddGeofenceComponent
      ),
  },
  {
    path: ':objectId',
    loadComponent: () =>
      import('./pages/geofence-details/geofence-details.component').then(
        (m) => m.GeofenceDetailsComponent
      ),
    children: [
      { path: '', redirectTo: 'snapshot', pathMatch: 'prefix' },
      {
        path: 'snapshot',
        loadComponent: () =>
          import(
            './components/geofence-snapshot/geofence-snapshot.component'
          ).then((m) => m.GeofenceSnapshotComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'geofences', subModule: 'snapshot' },
      },
      {
        path: 'assets',
        loadComponent: () =>
          import(
            './components/geofence-asset-list/geofence-asset-list.component'
          ).then((m) => m.GeofenceAssetListComponent),
      },
      {
        path: 'configuration',
        loadComponent: () =>
          import(
            './components/geofence-configuration/geofence-configuration.component'
          ).then((m) => m.GeofenceConfigurationComponent),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'geofences', subModule: 'configuration' },
      },
      {
        path: 'events',
        loadComponent: () =>
          import('./components/geofence-events/geofence-events.component').then(
            (m) => m.GeofenceEventsComponent
          ),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'geofences', subModule: 'events' },
      },
      {
        path: 'groups',
        loadComponent: () =>
          import('./components/geofence-groups/geofence-groups.component').then(
            (m) => m.GeofenceGroupsComponent
          ),
        canActivate: [
          (route: ActivatedRouteSnapshot) =>
            inject(RouteAuthGuard).canActivate(route),
        ],
        data: { module: 'geofences', subModule: 'groups' },
      },
      {
        path: 'audit-log',
        loadComponent: () =>
          import('../../shared/components/audit-log/audit-log.component').then(
            (m) => m.AuditLogComponent
          ),
        resolve: { objectId: ObjectIdResolver },
        data: { module: 'geofences', subModule: 'audit-log' },
      },
    ],
  },
];
