import { STORE_HIERARCHIES_DATA } from '../actions/action-types';

const initialState = null;

export function hierarchiesDataReducer(state = initialState, action): any {
  switch (action.type) {
    case STORE_HIERARCHIES_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default: {
      return state;
    }
  }
}
